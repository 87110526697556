import { Button } from '@/components/common';
import { OUTER_URLS } from '@/constants/common';
import { GA4_SCREEN_NAME } from '@/constants/ga4CustomEvent';
import { ocrSignInUrl } from '@/helpers/url.helper';
import { useGAEvent } from '@/hooks';
import { useAuthStore } from '@/recoil/authStore/useAuthStore';
import Head from 'next/head';
import { useState } from 'react';

import { useWeb3Auth } from '@/services/web3Auth/Web3Auth';
import { TicketScan } from '@/assets/images';

const LoginPage = () => {
  useGAEvent(GA4_SCREEN_NAME.STEP2_LOGIN_SCREEN);

  const { web3AuthLogout, connectWeb3AuthAndSignInWithEthereum } = useWeb3Auth();
  const { isAuthenticated } = useAuthStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleSubmitLogout = () => {
    setIsLoading(true);
    web3AuthLogout(ocrSignInUrl());
  };

  const handleSubmitConnectWeb3 = () => {
    setIsLoading(true);
    connectWeb3AuthAndSignInWithEthereum().finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      <Head>
        <title>アスティーダ招待 チケット for web</title>
      </Head>
      <div className="flex flex-col justify-center items-center font-noto gap-8">
        <div className="flex flex-col item-center gap-2">
          <h2 className="text-center font-bold text-2xl">パシャチケ</h2>
          <p className="text-center text-sm">
            お手持ちのチケットを
            <br />
            写真に撮って特典を獲得しよう！
          </p>
        </div>
        <div className="max-w-[263px] w-full h-full aspect-square">
          <TicketScan />
        </div>
        <div className="w-full flex flex-col items-center text-center gap-4">
          <div className="flex items-center gap-2 px-4 w-full">
            <input
              type="checkbox"
              className="form-checkbox h-6 min-w-6 rounded-[3px] accent-checkboxFillColor"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            <label className="text-sm text-black1 text-left leading-[21px]">
              <a
                href={OUTER_URLS.TERM}
                className="text-primaryRed underline font-bold"
                target="_blank"
                rel="noreferrer"
              >
                利用規約
              </a>
              ・
              <a
                href={OUTER_URLS.PRIVACY}
                className="text-primaryRed underline font-bold"
                target="_blank"
                rel="noreferrer"
              >
                プライバシーポリシー
              </a>
              <span className="text-sm font-normal whitespace-pre-wrap">{'に同意し\nて取得する。'}</span>
            </label>
          </div>
          <div className="w-full flex flex-col gap-2">
            <div className="w-full flex flex-col gap-4">
              {isAuthenticated ? (
                <Button type="submit" isLoading={isLoading} onClick={handleSubmitLogout}>
                  ログアウト
                </Button>
              ) : (
                <>
                  <Button type="submit" isLoading={isLoading} onClick={handleSubmitConnectWeb3} disabled={!isChecked}>
                    登録 / ログイン
                  </Button>
                  {/* <WalletConnectButton title="Web3ウォレットでログインする方はコチラ" /> */}
                </>
              )}
            </div>
            <div className="text-center leading-[71px]">
              <a href={OUTER_URLS.HOW_TO_USE} className="underline" target="_blank" rel="noreferrer">
                使い方
              </a>
            </div>
          </div>
          <p className="w-full bg-neutral-background-grey p-4 rounded-lg text-neutral-descriptionText text-center text-xs">
            ※電子招待チケットをご利用されたことがある方は
            <br />
            同一アカウントでご利用いただけます
          </p>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
